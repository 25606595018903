<script>
import {useCartStore} from "@/js/stores/cart.js";
import {useInternalStore} from "@/js/stores/internal.js";

export default {
    setup() {
        const cartStore = useCartStore();
        const internalStore = useInternalStore();
        return {
            cartStore,
            internalStore,
            productQty: cartStore.getProductCount
        };
    },
    name: "product-list",
    props: {
        products_list: {
            required: true,
            default: () => []
        }
    },
    data() {
        return {
            isLongPress: false,
            showDialog: false,
            longPressTimeout: null,
            tpmProduct: null
        }
    },
    methods: {
        addToCard(product) {
            this.cartStore.addProductToCart(product);
            if (window.innerWidth < 960) {
                this.$mitt.emit('show_mobile_qty', product.uid)

            }
        },
        startLongPress(product) {
            this.isLongPress = false;
            this.longPressTimeout = setTimeout(() => {
                this.isLongPress = true;
                this.onLongPress(product);
            }, 400); // Час у мілісекундах, що визначає довгий натиск
        },
        endLongPress() {
            clearTimeout(this.longPressTimeout);
        },
        onLongPress(product) {
            this.tpmProduct = product;
            if (this.productQty(this.tpmProduct.uid)>0){
                this.showDialog = true;
            }
        },
        removeItem() {
            this.showDialog = false;
             this.cartStore.removeProduct({product:this.tpmProduct});
        }
    }
}
</script>

<template>
    <v-row dense class="subcategory">
        <v-dialog
            max-width="400"
            persistent
            z-index="99999999999"
            app
            v-model="showDialog"
        >
            <v-card
                prepend-icon="info"
                title="Видалити?"
                :text="tpmProduct.name || ''"
            >
                <template v-slot:actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="showDialog = false">
                        Відмінити
                    </v-btn>

                    <v-btn
                        variant="tonal"
                        @click="removeItem">
                        Так
                    </v-btn>
                </template>
            </v-card>

        </v-dialog>
        <v-col
            cols="6"
            sm="4"
            md="4"
            :lg="internalStore.show_catalog? 4:3"
            v-for="product in products_list" :key="product.uid">
            <v-card @click="addToCard(product)"
                    height="100%"
                    class="product__card"
                    @touchstart="startLongPress(product)"
                    @touchend="endLongPress"
            >

                <div class="card__wrapper d-flex flex-column">
                    <v-badge color="success" class="product__budge" v-if="productQty(product.uid)">
                        <template v-slot:badge>{{ productQty(product.uid) }}</template>
                    </v-badge>
                    <v-img
                        aspect-ratio="2.5"
                        :src="`/category/images/${product.img?product.img:product.uid+'.jpg'}`"
                        cover
                    >

                        <template v-slot:placeholder>
                            <v-row
                                align="center"
                                class="fill-height ma-0"
                                justify="center"
                            >
                                <v-progress-circular
                                    color="grey-lighten-5"
                                    indeterminate
                                ></v-progress-circular>
                            </v-row>
                        </template>
                    </v-img>
                    <v-card-title class="card__title">
                        <span class="product-name">{{ product.name }}</span>
                        <span class="product-price">{{ product.price }} ₴</span>
                    </v-card-title>
                </div>
            </v-card>
        </v-col>
    </v-row>
</template>

<style lang="scss">
.product__card {
    position: relative;
    overflow: hidden;

    .product__budge {
        position: absolute;
        z-index: 2;
        right: 5px;
        top: 5px;

        .v-badge__badge {
            border: 1px solid black;
            left: initial !important;
            bottom: initial !important;
            position: relative;
            box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.75);
        }

    }
}

.subcategory {
    .card__wrapper {
        height: 100%;

        .v-card-title {
            flex-grow: 1;
            display: flex;
            flex-direction: column;

            .product-name {
                white-space: initial;
                flex-grow: 1;
            }
        }
    }


    .product-price {
        font-weight: 700;
        text-align: left;

    }
}


</style>
