<script>
import Breadcrumbs from "@/js/components/breadcrumbs.vue";
import api from "@/js/api.js";
export default {
    name: "top-bar",
    components: {Breadcrumbs},
    props: ['userRoles', 'role','isAdmin'],
    data() {
        return {
            drawer: false,
            show_login_drop: false,
            model: this.role
        }
    },
    methods: {
        logOut() {
            this.$mitt.emit('user-logout');
        },
        changeRole() {
            this.$mitt.emit('user-change-role', this.model);
        },
        getNameRole() {
            return this.userRoles.filter((r) => r.id == this.role)[0]?.name || "Не авторизован";
        },
        async imageDownload($e){
            $e.preventDefault();
            this.$mitt.emit('show-global-loader', true);
            try {
                let response = await api.imageDownload();
                window.location.reload();
                this.$mitt.emit('show-global-loader', false);

            } catch (error) {
                this.$mitt.emit('show-global-loader', false);
                console.error(error)
                this.$mitt.emit('show_msg', {
                    msg: 'Get orders error'
                })
            }
        }
    },
    mounted() {
        this.$mitt.on('change-role', () => {
            this.changeRole()
        });
    }
}
</script>

<template>
    <v-app-bar color="primary " flat app>
        <template v-slot:prepend>
            <v-btn icon="menu" @click.stop="drawer = !drawer"></v-btn>
            <v-btn icon="arrow_back" :to="{name:'orders-list'}" v-if="$route.name != 'orders-list'"></v-btn>
            <v-menu
                v-model="show_login_drop"
                :close-on-content-click="false"
                location="bottom"
                offset="15"
                z-index="9999999999"
                app
            >
                <template v-slot:activator="{ props }">
                    <v-btn
                        variant="text"
                        v-bind="props"
                    >
                        <v-icon icon="account_circle" class="mr-1"></v-icon>
                        <span class="user_role">
                            {{ getNameRole() }}
                        </span>
                    </v-btn>
                </template>
                <v-card
                    min-width="150"
                    class="pa-2"
                >
                    <v-card-title class="d-sm-none">
                        {{ getNameRole() }}
                    </v-card-title>
                    <v-btn variant="text" width="100%" color="red" @click.stop="logOut()">
                        <v-icon icon="logout"></v-icon>
                        Вийти
                    </v-btn>
                </v-card>
            </v-menu>
            <v-btn
                class="qr__btn pa-0"
                variant="text"
                href="https://t.me/gasparyancom"
                target="_blank"
                min-width="36"

            >
                <img src="@/assets/img/test_qr.jpg" height="36px" width="36px" style="object-fit: contain" alt="">
            </v-btn>

        </template>

        <breadcrumbs/>
        <template v-slot:append>
            <router-link :to="{name:'orders-list'}" class="app__logo align-center d-flex">
                <img src="@/assets/img/logo-white.png" width="200px" alt="logo" class="logo">
            </router-link>

        </template>


    </v-app-bar>
    <v-navigation-drawer
        v-model="drawer"
        location="left"
        temporary
    >
        <v-list>
            <v-list-item :to="{ name: 'orders-list' }">
                <v-list-item-title>Список замовлень</v-list-item-title>
            </v-list-item>

            <v-list-item v-if="isAdmin" @click="imageDownload">
                <v-list-item-title>Завантажити зображення</v-list-item-title>
            </v-list-item>
            <v-spacer></v-spacer>
        </v-list>
        <template v-slot:append>
            <div class="bar__bottom">
                <v-row class="pa-2">
                    <v-col cols="12">
                        <v-select
                            variant="underlined"
                            label="Тип користувача"
                            v-model="model"
                            :items="userRoles"
                            item-title="name"
                            item-value="id"
                            @update:modelValue="changeRole()"
                        ></v-select>
                    </v-col>
                </v-row>
            </div>
        </template>
    </v-navigation-drawer>
</template>

<style lang="scss">
.qr__btn {
    .v-btn__overlay {
        background-color: transparent !important;
    }

    .v-btn__underlay {
        background-color: transparent !important;
    }

    &:hover {
        background-color: transparent;
    }
}

@media screen and (max-width: 600px) {
    .user_role {
        display: none;
    }
    .app__logo {
        img {
            width: 150px;
        }
    }
}
</style>
